import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

const Privacy = () => (
    <Layout>
        <SEO title="Privacy policy"/>
        <section className="contentbox wrap --wideblue">
            <div class="txt_full_box text_left termsbox">
                <h2>
                    Privacy policy
                </h2>

                <p>NAVIAPPS HOLDING LIMITED built the BoatPilot EU app as a Free app. This SERVICE is provided by
                    NAVIAPPS HOLDING LIMITED at no cost and is intended for use as is.</p>
                <p>This page is used to inform visitors regarding our policies with the collection, use, and disclosure
                    of Personal Information if anyone decided to use our Service.</p>
                <p>If you choose to use our Service, then you agree to the collection and use of information in relation
                    to this policy. The Personal Information that we collect is used for providing and improving the
                    Service. We will not use or share your information with anyone except as described in this Privacy
                    Policy.</p>
                <h3>Information Collection and Use</h3>
                <p>For a better experience, while using our Service, we may require you to provide us with certain
                    personally identifiable information, including but not limited to Name, Email, Phone, Geo
                    Location, Gps tracks. The information that we request will be retained by us and used as
                    described in this privacy policy.</p>
                <p>The app does use third party services that may collect information used to identify you.</p>
                <p>Link to privacy policy of third party service providers used by the app</p>
                <ul>
                    <li><a href="https://policies.google.com/privacy">Google Play</a></li>
                    <li><a href="https://firebase.google.com/policies/analytics">Firebase Analytics</a></li>
                    <li><a href="https://aws.amazon.com/ru/privacy/"> Web Services</a></li>
                </ul>
                <h3>Log Data</h3>
                <p>We want to inform you that whenever you use our Service, in a case of an error in the app we
                    collect data and information (through third party products) on your phone called Log Data. This
                    Log Data may include information such as your device Internet Protocol (“IP”) address, device
                    name, operating system version, the configuration of the app when utilizing our Service, the
                    time and date of your use of the Service, and other statistics.
                </p>
                <h3>Cookies</h3>
                <p>Cookies are files with a small amount of data that are commonly used as anonymous unique
                    identifiers. These are sent to your browser from the websites that you visit and are stored on
                    your device & apps internal memory.
                </p>
                <p>This Service does not use these “cookies” explicitly. However, the app may use third party code
                    and libraries that use “cookies” to collect infor дmation and improve their services. You have
                    the option to either accept or refuse these cookies and know when a cookie is being sent to your
                    device. If you choose to refuse our cookies, you may not be able to use some portions of this
                    Service.
                </p>
                <h3>Service Providers</h3>
                <p>We may employ third-party companies and individuals due to the following reasons:
                </p>
                <ul>
                    <li>To facilitate our Service;</li>
                    <li>To provide the Service on our behalf;</li>
                    <li>To perform Service-related services; or</li>
                    <li>To assist us in analyzing how our Service is used.</li>
                </ul>
                <p>
                    We want to inform users of this Service that these third parties have access to your Personal
                    Information. The reason is to perform the tasks assigned to them on our behalf. However, they
                    are obligated not to disclose or use the information for any other purpose.
                </p>
                <h3>Security</h3>
                <p>We value your trust in providing us your Personal Information, thus we are striving to use
                    commercially acceptable means of protecting it. But remember that no method of transmission over
                    the internet, or method of electronic storage is 100% secure and reliable, and we cannot
                    guarantee its absolute security.
                </p>
                <h3> Links to Other Sites</h3>
                <p>This Service may contain links to other sites. If you click on a third-party link, you will be
                    directed to that site. Note that these external sites are not operated by us. Therefore, we
                    strongly advise you to review the Privacy Policy of these websites. We have no control over and
                    assume no responsibility for the content, privacy policies, or practices of any third-party
                    sites or services.
                </p><h3>Your rights</h3>
                <p>You have the right, subject to the conditions set out in the General Data Protection Regulation
                    (“GDPR”) or other applicable law, to request from Naviapps access to and rectification or
                    erasure of your personal data, data portability, restriction of processing of your personal
                    data, the right to object to processing of your personal data, and the right to lodge a
                    complaint with a supervisory authority. For more information about these rights, please visit
                    the European Commission’s “My Rights” page relating to GDPR, which can be displayed in a number
                    of languages.
                </p>
                <h3>Changes to This Privacy Policy</h3>
                <p>We may update our Privacy Policy from time to time. Thus, you are advised to review this page
                    periodically for any changes. We will notify you of any changes by posting the new Privacy
                    Policy on this page. These changes are effective immediately after they are posted on this page.
                </p>
                <h3>Contact Us</h3>
                <p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us
                    at info@boatpilot.me.</p>

                <p>The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is
                    accessible at BoatPilot EU unless otherwise defined in this Privacy Policy.</p>
            </div>

        </section>
    </Layout>
);

export default Privacy;
